<template>
  <div>
    <div class="wave"></div>
    <div class="wave"></div>
    <div class="wave"></div>
  </div>

  <div class="main-container">
    <div class="content-wrap">
      <div id="homepage-slider" class="st-slider">

        <input type="radio" class="cs_anchor radio d-none" name="slider" id="slide1"/>
        <input type="radio" class="cs_anchor radio d-none" name="slider" id="slide2"/>
        <input type="radio" class="cs_anchor radio d-none" name="slider" id="slide3"/>
        <input type="radio" class="cs_anchor radio d-none" name="slider" id="play1" checked=""/>

        <div class="images">
          <div class="images-inner">
            <div class="image-slide" v-for="img in images">
              <div class="image bg-yellow img-wrap" :style="{ backgroundImage: 'url(' + img + ')' }"></div>
            </div>
          </div>
        </div>

        <div class="labels d-none">
          <label for="slide1" class="label">text slide 1</label>
          <label for="slide2" class="label">text slide 2</label>
          <label for="slide3" class="label">text slide 3</label>

          <div class="fake-radio d-none">
            <label for="slide1" class="radio-btn"></label>
            <label for="slide2" class="radio-btn"></label>
            <label for="slide3" class="radio-btn"></label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="show-name">
    <div class="p-2">
      <div class="text-center">வானலைகளில்:</div>
      <div class="name">{{ showNow.name }}</div>
    </div>
    <div class="text-center bg-dark text-white p-1">
      <small><i class="bi bi-caret-right-fill text-danger"></i> அடுத்து:</small> <b>{{ showNext.name }}</b>
    </div>
  </div>

  <div class="scroller-container">
    <div class="bg-danger scroller-title">
      முக்கிய&nbsp;செய்தி
    </div>
    <marquee class="scroller">
      <p>
        <span v-for="post in posts">
          <span style="font-size: 35px; margin-right: 5px">
        <i class="bi bi-caret-right-fill"></i>
      </span> {{ post.title }}
        </span>
      </p>
    </marquee>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'Home',
  data() {
    return {
      posts: [],
      testColor: '',
      showNow: '',
      showNext: '',
      images: '',
    }
  },
  mounted() {
    this.getTitles();
    this.getShowName();
    this.getImages();
  },
  methods: {
    getTitles() {
      axios.get('https://etr.fm/api/news').then(response => {
        this.posts = response.data.posts;
      })
    },
    getShowName() {
      axios.get('https://etr.fm/api/show').then(response => {
        this.showNow = response.data.now;
        this.showNext = response.data.next;
      })
    },
    getImages() {
      axios.get('https://etr.fm/api/images').then(response => {
        this.images = response.data.images;
        console.log(this.images);
      })
    },
  }
}
</script>

<style scoped>
.scroller-container {
  display: flex;
  padding: 0;
  background: #ffffff;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.scroller-title {
  display: flex;
  align-items: center;
  padding: .6rem .75rem .75rem .75rem;
  font-size: 40px;
  color: #ffffff;
  font-weight: bold;
}

.scroller {
  display: flex;
  align-items: center;
  color: #002330;
  font-size: 40px;
}

.show-name {
  background: #f5af23;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 20px;
}

.show-name .name {
  font-size: 50px;
  text-align: center;
  margin-top: -20px;
}

.main-container {
  padding: 1rem;
}

.main-container .content-wrap {
  background: #ffffff80;
  padding: 1rem;
}

.main-container .img-wrap {
  min-height: calc(100vh - 140px);
  min-width: 100%;
  background-size: cover;
  background-position: center center;
}


.image {
  width: 100%;
  height: 200px;
}

.images {
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.images-inner {
  width: 500%;
  transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
}

.image-slide {
  width: 20%;
  float: left;
}

.image-slide,
.fake-radio,
.radio-btn {
  transition: all 0.5s ease-out;
}

.fake-radio {
  float: right;
}


/* Move slides overflowed container */
#slide1:checked ~ .images .images-inner {
  margin-left: 0;
}

#slide2:checked ~ .images .images-inner {
  margin-left: -100%;
}

#slide3:checked ~ .images .images-inner {
  margin-left: -200%;
}


/* Color of bullets */
#slide1:checked ~ div .fake-radio .radio-btn:nth-child(1),
#slide2:checked ~ div .fake-radio .radio-btn:nth-child(2),
#slide3:checked ~ div .fake-radio .radio-btn:nth-child(3) {
  background: red;
}

.radio-btn {
  width: 9px;
  height: 9px;
  border-radius: 5px;
  background: gray;
  display: inline-block !important;
  margin: 0 1px;
  cursor: pointer;
}

/* Color of bullets - END */


/* Text of slides */
#slide1:checked ~ .labels .label:nth-child(1),
#slide2:checked ~ .labels .label:nth-child(2),
#slide3:checked ~ .labels .label:nth-child(3) {
  opacity: 1;
}

.label {
  opacity: 0;
  position: absolute;
}

/* Text of slides - END */


/* Calculate AUTOPLAY for BULLETS */
@keyframes bullet {
  0%, 33.32333333333334% {
    background: red;
  }
  33.333333333333336%, 100% {
    background: gray;
  }
}


#play1:checked ~ div .fake-radio .radio-btn:nth-child(1) {
  animation: bullet 12300ms infinite -1000ms;
}

#play1:checked ~ div .fake-radio .radio-btn:nth-child(2) {
  animation: bullet 12300ms infinite 3100ms;
}

#play1:checked ~ div .fake-radio .radio-btn:nth-child(3) {
  animation: bullet 12300ms infinite 7200ms;
}

/* Calculate AUTOPLAY for BULLETS - END */


/* Calculate AUTOPLAY for SLIDES */
@keyframes slide {
  0%, 25.203252032520325% {
    margin-left: 0;
  }
  33.333333333333336%, 58.53658536585366% {
    margin-left: -100%;
  }
  66.66666666666667%, 91.869918699187% {
    margin-left: -200%;
  }
}


.st-slider > #play1:checked ~ .images .images-inner {
  animation: slide 12300ms infinite;
}

/* Calculate AUTOPLAY for SLIDES - END */


/* Calculate AUTOPLAY for CAPTION */
@keyframes caption {
  0%, 33.32333333333334% {
    opacity: 1;
  }
  33.333333333333336%, 100% {
    opacity: 0;
  }
}


#play1:checked ~ .labels .label:nth-child(1) {
  animation: caption 12300ms infinite -1000ms;
}

#play1:checked ~ .labels .label:nth-child(2) {
  animation: caption 12300ms infinite 3100ms;
}

#play1:checked ~ .labels .label:nth-child(3) {
  animation: caption 12300ms infinite 7200ms;
}
</style>
