import {createStore} from 'vuex'
import axios from "axios";

export default createStore({
    state: {},
    getters: {},
    mutations: {
    },
    actions: {

    },
    modules: {}
})
